import React from 'react';


export default () => (
  <div className="terms-iframe">
    <div className="width-toggle"/>
    <iframe
      src={'https://v1.embednotion.com/embed/56f28465557b4963b2b42c90e50d2faf'}
      frameBorder="0"
      style={{ position: 'relative', minHeight: '100vh', width: '100%' }}
    />
  </div>
);
